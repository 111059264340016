<template>
    <Modal 
        :ref="modalId" 
        :loading="loading" 
        :title="title" 
        :name="modalId" 
        :submitButtonText="$t('actions.confirm')" 
        @submit="submitModal" 
        @closed="cancelModal" 
        class="confirm_modal" 
        width="30%"
    >
        <template #content v-if="message">
            {{ message }}
        </template>
    </Modal>
</template>

<script>
/* 
    Use for getting confirmation result.
    Example:
    const confirm = await this.$refs['modal-confirm'].show(config);
    config includes params: title, message, autoclose.
    Use autoclose: true to close 
*/
import Modal from "@/components/modal-content";
import { v4 as uuidv4 } from 'uuid';

export default {
    name: "modal-confirm",
    components: {
       Modal,
    },
    data() {
        return {
            message: "",
            loading: false,
            autoclose: true,
            resolvePromise: undefined,
            rejectPromise: undefined,
            i: 0,
            modalId: uuidv4()
        };
    },
    computed: {
        title() {
            return this.$t('components.confirmation');
        },
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title;
            this.message = opts.message;
            this.autoclose = opts.autoclose ?? true;
            this.loading = false;
            this.$refs[this.modalId].showModal();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },
        submitModal() {
            this.resolvePromise(true);
            if (this.autoclose) this.$refs[this.modalId].hideModal();
            else this.loading = true;
        },
        cancelModal() {
            this.loading = false;
            this.resolvePromise(false);
            // if (this.autoclose) this.$refs.confirmModal.hideModal();
        },
        close() {
            // this.loading = false;
            this.$refs[this.modalId].hideModal();
        },
    },
};
</script>

<style lang="scss" scoped>
    .confirm_modal{
        z-index: 9999 !important;
    }
</style>
