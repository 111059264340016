<template>
    <div class="flex flex-col" :class="value === 'lexis' ? 'flex-col-reverse gap-2' : 'my-2'">
        <div class="flex" v-if="checkPermission('checkOutput.source_name')">
            <div v-if="value !== 'lexis'" :class="{'rotate-90': isExpanded, 'cursor-pointer': !disabled}" class="duration-200 transform" @click="handleSourceExpand">
                <font-awesome-icon icon="angle-right"> </font-awesome-icon>
            </div>
            <input class="cursor-pointer" :class="value !== 'lexis' ? 'mx-4' : 'mr-4'" v-model="isExpanded" type="checkbox" :value="value" @change="handleSourceChange" :disabled="disabled" />
            <span class="text-black" v-text="title" />
        </div>

        <div v-if="isFilter" :class="[isExpanded ? 'h-auto' : 'transform -translate-y-full hidden']" class="transition-all duration-200">
            <div :class="value === 'lexis' ? '' : 'py-5 px-4'" v-if="value !== 'judyrecords'">
                <slot />
            </div>

            <div class="w-full text-center border-0 border-b border-solid border-gray-200" style="line-height: 0.1em" v-if="!['judyrecords', 'lexis'].includes(value)" />
        </div>
    </div>
</template>

<script>
import { checkPermission } from "@shared/utils/functions";
export default {
    /**
     * @property
     * dynamic properties passed to the component with default value
     */
    props: {
        isExpanded: Boolean,
        title: {type: String, required: true},
        value: {type: String, default: ""},
        isFilter: {type: Boolean, default: true},
        disabled: {type: Boolean, default: false},
    },
    computed: {},
    methods: {
        checkPermission,
        /**
         * @method
         * @param {object} e - pointer event on the checkbox input
         */
        handleSourceChange(e) {
            this.$emit("handleSourceChange", e);
        },

        /**
         * @method - expand / collapse source
         */
        handleSourceExpand() {
            if (!this.disabled) this.$emit("handleSourceExpand");
        },
    },
};
</script>
